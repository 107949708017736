// Small mobile devices
@mixin xs {
    @media screen and (max-width: #{$screen-xs-max}) {
        @content;
    }
}

// Mobile devices
@mixin mobile {
    @media screen and (max-width: #{$screen-mobile-max}) {
        @content;
    }
}

// Tablet devices
@mixin tablet {
    @media screen and (max-width: #{$screen-tablet-max}) {
        @content;
    }
}

// Desktop devices
@mixin medium {
    @media screen and (max-width: #{$screen-medium-max}) {
        @content;
    }
}

// Custom devices
@mixin screen-up($screen) {
    @media screen and (min-width: $screen + 'px') {
        @content;
    }
}

@mixin screen-down($screen) {
    @media screen and (max-width: $screen + 'px') {
        @content;
    }
}

@mixin dir-bg($color) {
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-style: solid;
        border-width: 6rem 50vw;
        border-color: transparent $color $color transparent;

        @include tablet {
            border-width: 4rem 50vw;
        }

        @include mobile {
            border-width: 2rem 50vw;
        }
    }
}
