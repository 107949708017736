.footer {
    min-height: auto;
    padding-bottom: 15rem;
    background: $brown;
    color: $white;

    @include mobile {
        padding-bottom: 10rem;
    }

    .container {
        gap: 3rem 2rem;

        @include screen-down(1300) {
            justify-content: flex-start;
        }
    }

    &__col {
        flex: 0 0 33.6rem;
        max-width: 100%;

        &--1 {
            flex: 0 0 9.5rem;
            margin-right: 6rem;

            @include medium {
                flex: 0 0 8.1rem;
            }

            @include screen-down(1300) {
                margin-right: 3rem;
            }
        }
    }

    &__title {
        margin-bottom: 1.8rem;
        font-weight: bold;
        font-size: 2rem;

        @include screen-down(1300) {
            font-size: 1.8rem;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        margin-bottom: 1rem;

        a {
            text-transform: uppercase;
        }
    }

    p {
        margin-bottom: 2rem;
        font-weight: 300;
        line-height: 1.9rem;
    }

    a,
    .btn-icon {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .btn-icon {
        margin-top: 2rem;
        color: inherit;
    }
}

.social-links {
    gap: 1.5rem;
    font-size: 3rem;

    a {
        display: block;
        padding: .5rem;

        &:hover {
            transform: scale(1.1);
            text-decoration: none;
        }
    }

    .icon-instagram {
        font-size: 3.2rem;
    }
}
