/* stylelint-disable */
.tarteaucitronCTAButton,
#tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow, #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny,
.tac_activate .tarteaucitronAllow,
.tac_activate .tac_float .tarteaucitronAllow{
    background: $white !important;
    color: $black !important;
}

#tarteaucitronRoot .tarteaucitronCheck::before,
#tarteaucitronRoot .tarteaucitronCross::before {
    color: $black !important;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
    font-family: $font-primary !important;
}
/* stylelint-enable */
