button {
    cursor: pointer;
    font-family: $font-primary;
}

.btn {
    display: inline-flex;
    align-items: center;
    gap: .7rem;
    padding: 1.3rem 2rem;
    border-radius: 2.5rem;
    background: $red;
    color: $white;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.5rem;
    text-decoration: none;
    white-space: nowrap;
    transition: .3s all ease-in-out;

    @include xs {
        font-size: 1.3rem;
    }

    .icon {
        font-size: 1.7rem;
    }

    &:hover {
        background: darken($red, 7%);
    }
}

.btn-icon {
    border: 0;
    background: 0;
}
