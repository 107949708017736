.back-to-top {
    position: fixed;
    right: 4rem;
    bottom: 4rem;
    z-index: 999;
    width: 4.5rem;
    height: 4.5rem;
    box-shadow: .1rem .1rem .5rem .1rem rgba($black, .1);
    border-radius: 50%;
    background: $orange;
    color: $white;
    font-size: 1.1rem;
    opacity: 0;
    transition: transform .3s;

    @include mobile {
        right: 2rem;
        bottom: 2rem;
    }

    .icon {
        display: inline-block;
        transform: rotate(-180deg);
        pointer-events: none;
    }

    &.is-visible {
        opacity: 1;
    }

    &:hover,
    &:focus {
        transform: scale(1.1);
    }
}
