.section--5 {
    background: $beige;

    &::after {
        border-color: transparent $brown $brown transparent;
    }

    &__intro {
        margin-bottom: 18rem;
        font-weight: bold;
        font-size: 2rem;

        @include medium {
            margin-bottom: 12rem;
        }

        @include mobile {
            margin-bottom: 6rem;
            font-size: 1.8rem;
        }
    }

    &__block-1 {
        margin-bottom: 14rem;

        &__title {
            margin-bottom: 6rem;

            @include mobile {
                margin-bottom: 4rem;
            }
        }

        &__row {
            gap: 5rem;

            @include tablet {
                flex-direction: column;
            }
        }

        h4 {
            margin: 0 0 2rem;
            color: $grey;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 1.8rem;
        }

        &__col--2 {
            flex: 1;
            margin-top: -4rem;

            @include tablet {
                margin: 0;
            }

            picture {
                cursor: url('../images/zoom-in.svg'), auto;
            }
        }
    }

    &__block-2 {
        margin-bottom: 27rem;

        @include medium {
            margin-bottom: 20rem;
        }

        &__title {
            margin-bottom: 3rem;
        }
    }

    &__block-3 {
        margin-bottom: 20.5rem;

        @include medium {
            margin-bottom: 15rem;
        }

        &__title {
            margin-bottom: 3.8rem;
        }

        .btn {
            margin-bottom: 4.5rem;
        }
    }

    &__block-4 {
        &__title {
            margin-bottom: 3.8rem;
        }

        &__footer {
            margin-top: 4rem;
            font-size: 2rem;

            @include mobile {
                font-size: 1.8rem;
            }

            a {
                display: inline-block;
                vertical-align: middle;
                padding: 0 .5rem;
                color: $red;
                text-decoration: none;
                font-size: 3rem;

                @include mobile {
                    font-size: 2.8rem;
                }

                &:hover {
                    transform: scale(1.1);
                }
            }

            .icon-instagram {
                font-size: 3.2rem;

                @include mobile {
                    font-size: 3rem;
                }
            }
        }
    }
}

.video {
    position: relative;
    max-width: 141.2rem;
    height: 0;
    padding-top: 47%;

    /* stylelint-disable */
    iframe,
    .youtube_player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
    }
    /* stylelint-enable */
}

.hashtag {
    position: absolute;
    bottom: -4rem;
    left: -2rem;
    padding: .6rem 2.5rem;
    background: $black;
    color: $white;
    font-size: 4rem;
    line-height: 1.3;
    font-family: $font-secondary;
    transform: rotate(-4deg);

    @include tablet {
        bottom: -2rem;
        left: -1rem;
        padding: .4rem 1.5rem;
        font-size: 2rem;
    }
}
