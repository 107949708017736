.section--4 {
    &::after {
        border-color: transparent transparent $beige $beige;
    }

    .title {
        margin-bottom: 7.5rem;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8.5rem 7.8rem;

        @include medium {
            gap: 6rem;
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        flex: 0 0 calc(50% - 3.9rem);

        @include tablet {
            flex: 0 0 100%;
        }

        &:nth-child(even) {
            margin-top: 4.8rem;

            @include tablet {
                margin: 0;
            }
        }
    }

    h3 {
        height: 4.8rem;
        margin: 0;
        font-weight: normal;
    }

    .card {
        flex: 1;
        width: 100%;
        padding: 5.5rem 2rem 7rem;
        border: 3rem solid $orange-dark;

        @include mobile {
            padding: 3rem 2rem 6rem;
            border-width: 1.5rem;
        }

        &__title {
            font-weight: 800;
            font-size: 3rem;
            line-height: 1.2;

            @include mobile {
                font-size: 2.5rem;
            }
        }

        p {
            max-width: none;
            font-size: 2rem;

            @include mobile {
                font-size: 1.8rem;
            }
        }

        .btn-modal {
            right: auto;
            bottom: 2.8rem;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
