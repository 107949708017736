@import 'swiper/src/swiper';

.swiper-container {
    overflow: visible;
    padding-bottom: 8.3rem;

    @include medium {
        padding-bottom: 7rem;
    }
}

.swiper-scrollbar {
    border-radius: 1rem;
    position: relative;
    -ms-touch-action: none;
    background: #fbd3cb;

    .swiper-container-horizontal > & {
        position: absolute;
        left: 0;
        bottom: .3rem;
        z-index: 50;
        height: 1.5rem;
        width: 100%;
    }

    .swiper-container-vertical > & {
        position: absolute;
        right: .3rem;
        top: 1%;
        z-index: 50;
        width: 5px;
        height: 98%;
    }
}

.swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: $red;
    border-radius: 1rem;
    left: 0;
    top: 0;
}

.swiper-scrollbar-cursor-drag {
    cursor: move;
}

.swiper-scrollbar-lock {
    display: none;
}

.swiper-slide {
    position: relative;

    &:not(.swiper-slide-visible) {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $white;
            opacity: .7;
        }
    }
}
