.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    color: $white;

    &.theme-light {
        color: $black;
    }

    .container {
        height: 16rem;
        transition-duration: .5s;

        @include medium {
            height: 14.5rem;
        }

        @include mobile {
            height: 10.8rem;
        }
    }

    &-compact {
        .container {
            height: 8rem;
            align-items: center;

            @include medium {
                height: 9rem;
            }
        }

        .header__logo {
            position: absolute;
            top: -25rem;
        }
    }

    &__logo {
        flex: 0 0 8.1rem;
        margin-right: 3.3rem;
        padding-top: 1.5rem;
        transition-duration: .5s;

        @include medium {
            padding: 0;
        }

        @include mobile {
            flex: 0 0 6rem;
            margin-right: 2rem;
        }

        @include xs {
            margin-left: 1.5rem;
        }
    }

    &__site-name {
        margin: 0;
        padding: 0;
        font-weight: bold;
        font-size: 3.4rem;
        line-height: 1;
        color: inherit;

        @include medium {
            font-size: 3.2rem;
        }

        @include screen-down(1300) {
            font-size: 3rem;
        }

        @include tablet {
            margin-bottom: 1.5rem;
        }

        @include mobile {
            font-size: 1.9rem;
        }

        @include xs {
            font-size: 1.8rem;
        }
    }

    > img {
        position: absolute;
    }
}

.hamburger {
    display: none;
    padding: 0;

    @include tablet {
        display: block;
    }

    .bar {
        display: block;
        width: 4rem;
        height: .5rem;
        margin: .8rem 0;
        border-radius: .2rem;
        background: $white;
        box-shadow: .1rem .1rem 1rem .1rem rgba($black, .1);

        .theme-light & {
            background: $black;
        }
    }
}

.navigation {
    @include tablet {
        position: absolute;
    }

    &__inner {
        @include tablet {
            flex-direction: column;
            position: fixed;
            top: 0;
            left: -100%;
            z-index: 100;
            width: 35rem;
            height: 100%;
            padding: 8rem 4rem;
            background: $white;
            transition-duration: .5s;
            overflow-y: auto;
        }

        @include mobile {
            width: 100%;
            padding: 6rem 4rem;
        }
    }

    &__close {
        display: none;

        @include tablet {
            display: block;
            position: absolute;
            top: 2.5rem;
            right: 2.5rem;
            font-size: 2rem;
        }
    }

    &__overlay {
        @include tablet {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($black, .5);
        }
    }

    &.is-open {
        .navigation__overlay {
            display: block;
        }

        .navigation__inner {
            left: 0;
        }
    }
}

.menu {
    display: flex;
    gap: 3.5rem;
    margin: 0 0 0 10rem;
    padding: 0;
    list-style: none;

    @include medium {
        gap: 3rem;
    }

    @include tablet {
        flex-direction: column;
        margin: 0;
    }

    &-item {
        position: relative;
        padding: 0;
        border: 0;
        background: 0;
        cursor: pointer;
        color: inherit;
        font-weight: 800;
        font-size: 2rem;
        line-height: 1.4;

        @include tablet {
            color: $black;
            box-shadow: none;
        }

        @include medium {
            font-size: 1.8rem;
        }

        @include screen-down(1300) {
            font-size: 1.6rem;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: -1rem;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: .3rem;
            background: $white;
            opacity: 0;
            transition-duration: .3s;

            @include tablet {
                left: 0;
                transform: translateX(0);
            }

            .theme-light & {
                background: $black;
            }
        }

        &.is-active,
        &:hover {
            &:after {
                width: 4rem;
                opacity: .9;
            }
        }
    }
}
