$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: 'fonts' !default;

$icon-tiktok: '\e905';
$icon-twitter: '\e907';
$icon-facebook: '\e908';
$icon-brand: '\e908';
$icon-social: '\e908';
$icon-instagram: '\e906';
$icon-plus: '\e904';
$icon-close: '\e900';
$icon-chevron-down: '\e901';
$icon-chevron-left: '\e902';
$icon-chevron-right: '\e903';

