.section--3 {
    background: $beige-dark;

    &::after {
        border-color: transparent transparent $white $white;
    }

    .intro {
        max-width: none;
    }
}

.portraits {
    gap: 4rem 0;
    justify-content: center;
    margin: 0 -2rem;

    &__item {
        flex: 0 0 32rem;
        padding: 0 2rem;

        @include mobile {
            max-width: 100%;
        }

        &__title {
            margin: 1.6rem 0 0;
            color: $white;
            font-size: 1.6rem;

            a {
                text-decoration: none;

                &:hover {
                    color: $orange;
                }
            }
        }
    }
}

.overlay-link {
    display: block;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $orange;
        transition-duration: .3s;
        opacity: 0;
    }

    .icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        width: 3.7rem;
        height: 3.7rem;
        border-radius: 50%;
        background: $white;
        color: $orange;
        font-size: 1.2rem;
        transition-duration: .3s;
        opacity: 0;
    }

    &:hover,
    &:focus {
        &::before {
            opacity: .8;
        }

        .icon {
            opacity: 1;
        }
    }
}
