// Colors
$black: #312226;
$white: #fff;
$brown: #A55854;
$brown-2: #A35557;
$brown-light: #C86E70;
$brown-dark: #743545;
$orange: #F18757;
$orange-dark: #D36F42;
$red: #EB5D48;
$red-light: #FF695C;
$pink: #fbd3cb;
$beige: #FFF1EB;
$beige-dark: #EFCEBD;
$grey: #594E48;

// Breakpoints
$screen-xs-max: 374px;
$screen-mobile-max: 767px;
$screen-tablet-max: 1024px;
$screen-medium-max: 1489px;

// Fonts
$font-primary: 'Usual', sans-serif;
$font-secondary: 'Gotham', sans-serif;

// Icomoon
$icomoon-font-path: '../fonts/icomoon/fonts';
