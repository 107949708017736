@import 'variables';

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?cvklh2') format('truetype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?cvklh2') format('woff'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?cvklh2##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-tiktok {
    &:before {
        content: $icon-tiktok;
    }
}

.icon-twitter {
    &:before {
        content: $icon-twitter;
    }
}

.icon-facebook {
    &:before {
        content: $icon-facebook;
    }
}

.icon-brand {
    &:before {
        content: $icon-brand;
    }
}

.icon-social {
    &:before {
        content: $icon-social;
    }
}

.icon-instagram {
    &:before {
        content: $icon-instagram;
    }
}

.icon-plus {
    &:before {
        content: $icon-plus;
    }
}

.icon-close {
    &:before {
        content: $icon-close;
    }
}

.icon-chevron-down {
    &:before {
        content: $icon-chevron-down;
    }
}

.icon-chevron-left {
    &:before {
        content: $icon-chevron-left;
    }
}

.icon-chevron-right {
    &:before {
        content: $icon-chevron-right;
    }
}

