.modal {
    display: none;

    &.is-open {
        display: block;
    }
}

.modal__overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
}

.modal__container {
    position: relative;
    width: 72.8rem;
    max-width: calc(100% - 8rem);
    padding: 3rem 0;
    background-color: rgba(#F4F4F4, .95);

    @include mobile {
        max-width: calc(100% - 4rem);
        padding: 2rem 0;
    }

    &__inner {
        overflow-y: auto;
        max-height: calc(100vh - 20rem);
    }

    li {
        margin-bottom: 1.2rem;
    }
}

.modal__close {
    position: absolute;
    top: 0;
    left: -4.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.8rem;
    height: 3.8rem;
    border: 0;
    border-radius: 50%;
    background: $orange;
    color: $white;
    box-shadow: .1rem .1rem .5rem .1rem rgba($black, .1);
    font-size: 1.4rem;
    transition-duration: .3s;

    @include tablet {
        top: -4.7rem;
        left: 0;
    }

    &:hover {
        transform: scale(1.1);
    }
}

.modal__content {
    color: $brown-dark;
    overflow-y: auto;
    padding: 0 5.2rem;
    font-weight: 600;
    line-height: 1.2;

    @include mobile {
        padding: 0 1.5rem;
    }

    a {
        color: $red-light;
        text-decoration: none;

        &:hover {
            color: $red;
            text-decoration: underline;
        }
    }
}

/* Animation */
@keyframes mmfadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        transform: translateY(10%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-10%);
    }
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.is-open {
    display: block;
}

.micromodal-slide[aria-hidden='false'] .modal__overlay {
    animation: mmfadeIn .5s cubic-bezier(.34, .07, .095, .995);
}

.micromodal-slide[aria-hidden='false'] .modal__container {
    animation: mmslideIn .5s cubic-bezier(.34, .07, .095, .995);
}

.micromodal-slide[aria-hidden='true'] .modal__overlay {
    animation: mmfadeOut .5s cubic-bezier(.34, .07, .095, .995);
}

.micromodal-slide[aria-hidden='true'] .modal__container {
    animation: mmslideOut .5s cubic-bezier(.34, .07, .095, .995);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
    will-change: transform;
}

.modal-lg {
    .modal {
        &__close {
            top: 2rem;
            left: 2rem;
        }

        &__container {
            width: auto;
            max-width: 100%;
            padding: 0;
            background: $white;

            &__inner {
                max-height: 100vh;
                max-width: 100vw;
            }
        }

        &__content {
            padding: 0;
        }
    }

    img {
        max-width: none;
    }
}
