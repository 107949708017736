.d-flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: flex-end;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.d-block {
    display: block;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-center {
    text-align: center;
}

.hidden {
    display: none !important;
}

.w-100 {
    width: 100%;
}
