*,
*::before,
*::after {
    box-sizing: border-box;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

a {
    color: inherit;
    transition-duration: .3s;
}

p {
    margin: 0 0 2.5rem;

    &:last-child {
        margin: 0;
    }
}

.visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0,0,0,0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.skip-link {
    position: absolute;
    transform: translateY(-10rem);
    display: block;
    padding: 1rem;
    background: $white;
    z-index: 99999;

}

.focusable:focus-visible {
    transform: translateY(0);
}
