.section--1 {
    &::after {
        border-color: transparent transparent $orange $orange;
    }

    &__block {
        max-width: 113rem;
        margin: 0 auto;
        padding: 8rem 4rem;
        border: 1rem solid $brown;
        border-radius: .5rem;

        @include medium {
            padding: 6rem 4rem;
        }

        @include mobile {
            padding: 4.5rem 2.5rem;
            border-width: .5rem;
        }
    }

    .title {
        margin-bottom: 6rem;
        color: $red;

        @include medium {
            margin-bottom: 5rem;
        }

        @include tablet {
            margin-bottom: 4rem;
        }
    }

    p {
        max-width: 94rem;
        margin-right: auto;
        margin-left: auto;
        font-weight: 600;
        font-size: 1.8rem;

        strong {
            font-size: 2rem;
        }
    }
}
