html {
    font-size: .625em;
    line-height: 1.5;
}

body {
    color: $brown-dark;
    font-size: 1.6rem;
    font-family: $font-primary;
    line-height: 1.55;
    overflow-x: hidden;
}

.title {
    margin: 0 0 2.5rem;
    font-size: 6rem;
    line-height: 1.1;

    @include medium {
        font-size: 5rem;
    }

    @include tablet {
        font-size: 4rem;
    }

    @include mobile {
        font-size: 3rem;
    }
}

h3,
.like-h3 {
    margin: 0 0 5.5rem;
    font-weight: bold;
    font-size: 3rem;

    @include tablet {
        margin-bottom: 3rem;
        font-size: 2.5rem;
    }
}

.like-h4 {
    margin: 3rem 0 2rem;
    font-weight: bold;
    font-size: 2rem;
    text-transform: uppercase;

    &:first-child {
        margin-top: 0;
    }
}

.text-white {
    color: $white;
}

.text-brown {
    color: $brown;
}

.text-red {
    color: $red;
}

.text-red-light {
    color: $red-light;
}
