.section--2 {
    padding-bottom: 0;
    background: $orange;
    color: $white;

    &__block-1 {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 14rem;
        gap: 9.5rem 0;

        @include medium {
            gap: 6rem 0;
            margin-bottom: 12rem;
        }
    }

    &__block-2 {
        margin-bottom: 15rem;
        padding: 6.5rem 7.1%;
        background: #f6ab8a;

        @include medium {
            margin-bottom: 12rem;
        }

        @include tablet {
            padding: 5rem;
        }

        @include mobile {
            padding: 3rem 2rem;
        }

        .card {
            flex: 0 0 45%;

            @include tablet {
                flex: 0 0 100%;
            }
        }
    }

    &__block-3 {
        > .container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__title {
            margin-bottom: 31rem;

            @include medium {
                margin-bottom: 24rem;
            }

            @include tablet {
                margin-bottom: 23vw;
            }
        }

        &__img {
            position: relative;
            display: flex;
            justify-content: center;
            height: 2rem;

            @include dir-bg($beige-dark);

            &__inner {
                position: relative;
                z-index: 1;
                height: 44.8rem;
                margin-top: -25rem;

                @include medium {
                    height: 36.2rem;
                    margin-top: -18rem;
                }

                @include tablet {
                    height: 30vw;
                    margin-top: -14vw;
                }
            }

            img {
                margin: 0 auto;

                @include medium {
                    width: 30rem;
                }

                @include tablet {
                    width: 25vw;
                }

                @include mobile {
                    width: 30vw;
                }
            }
        }


        &__text {
            padding: 28rem 0 15rem;
            background: $beige-dark;
            font-weight: 600;
            font-size: 7rem;

            @include medium {
                padding-top: 19rem;
                font-size: 6rem;
            }

            @include tablet {
                padding-top: 22vw;
                font-size: 5rem;
            }

            @include mobile {
                font-size: 3rem;

                span {
                    display: block;
                }
            }
        }
    }
}

.intro {
    max-width: 105rem;
    margin-bottom: 5rem;
    font-size: 3rem;
    line-height: 1.25;

    @include medium {
        font-size: 2.8rem;
    }

    @include tablet {
        font-size: 2.5rem;
    }

    strong {
        margin-bottom: 1.5rem;
    }
}

.progress-wrap {
    position: relative;
    flex: 0 0 25%;
    padding: 0 1.2rem;

    @include screen-down(1300) {
        flex: 0 0 50%;
    }

    @include mobile {
        flex: 0 0 100%;
    }

    p {
        font-weight: bold;
        font-size: 2rem;
        line-height: 2.4rem;

        @include mobile {
            font-size: 1.8rem;
            line-height: 2rem;
        }
    }

    .percentage {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26.4rem;
        margin-bottom: 4rem;
        font-weight: 800;
        font-size: 6rem;

        @include medium {
            height: 22rem;
            margin-bottom: 3rem;
            font-size: 5rem;
        }

        @include mobile {
            height: 15rem;
            margin-bottom: 2.5rem;
            font-size: 3rem;
        }

        sup {
            position: relative;
            top: -1.2rem;
            left: .2rem;
            font-size: .5em;
        }
    }
}

/* Circumference = 2πr */
/* π = 3.1415926535898 */
/* r = 35 */
.radial-progress {
    position: absolute;
    top: -2.7rem;
    left: 50%;
    transform: translateX(-50%) rotate(-90deg);
    width: 31.5rem;
    max-width: 100%;

    @include medium {
        top: -2rem;
        width: 26rem;
    }

    @include mobile {
        top: -1.5rem;
        width: 17.5rem;
    }

    &-wrap {
        opacity: 1 !important;
    }

    circle {
        fill: rgba(0, 0, 0, 0);
        stroke: $brown;
        stroke-dashoffset: 219.91148575129; /* Circumference */
        stroke-width: 13;
        transition: stroke-dashoffset .8s;

        &.incomplete {
            stroke: #fcb08e;
        }

        &.complete {
            stroke-dasharray: 219.91148575129; /* Circumference */

            &.white {
                stroke: $white;
            }
        }
    }
}
