.main {
    overflow: hidden;
}

.container {
    width: 100%;
    max-width: 1490px;
    margin: 0 auto;
    padding: 0 4rem;

    @include mobile {
        padding: 0 2rem;
    }
}

.section {
    position: relative;
    min-height: 100vh;
    display: flex;
    padding: 20rem 0 20vw;

    @include medium {
        padding-top: 16rem;
    }

    @include mobile {
        padding-top: 14rem;
    }

    &--dir {
        @include dir-bg($white);
    }
}
