.section--intro {
    align-items: center;
    padding: 10rem 0;
    background: url('../images/intro-bg.png') center no-repeat $brown;
    background-size: cover;

    &__block {
        position: relative;
    }

    picture {
        width: 71.7rem;
        max-width: 100%;

        @include mobile {
            width: 39rem;
        }
    }
}

.btn-next {
    position: absolute;
    bottom: -3rem;
    left: 50%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    color: $white;
    font-size: 3.6rem;
    line-height: 1;
    animation: action .5s infinite  alternate;

    @include screen-down(1800) {
        font-size: 2.8rem;
    }

    @include medium {
        font-size: 2.2rem;
    }

    @include tablet {
        font-size: 1.8rem;
    }

    .icon {
        pointer-events: none;

        &:nth-child(2) {
            color: #d2acaa;
        }

        &:nth-child(3) {
            color: #c99a98;
        }

        &:nth-child(4) {
            color: darken(#c99a98, 5%);
        }

        &:nth-child(5) {
            color: #b77976;
        }

        &:nth-child(6) {
            color: #b77976;
        }

        &:nth-child(7) {
            color: #a55854;
        }
    }
}

@keyframes action {
    0% { transform: translateX(-50%) translateY(0); }

    100% { transform: translateX(-50%) translateY(-2rem); }
}
