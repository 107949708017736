.list-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10%;

    @include tablet {
        gap: 4rem 0;
    }
}

.card {
    position: relative;
    padding: 2.5rem 13rem 2.3rem 3.5rem;
    background: $orange;
    color: $white;

    @include mobile {
        padding: 2.5rem 2.5rem 6rem;
    }

    &--brown {
        border-color: $brown-2 !important;
        background: $brown-light;

        .btn-modal .icon {
            color: $brown-light;
        }
    }

    &__title {
        margin: 0 0 1.8rem;
        font-size: 1.6rem;
    }

    p {
        max-width: 33rem;
        font-weight: bold;

        @include mobile {
            max-width: 100%;
        }
    }

    .btn-icon {
        position: absolute;
        right: 1.9rem;
        bottom: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.8rem;
        height: 3.8rem;
        border-radius: 50%;
        background: $white;
        color: $orange;
        font-size: 1.5rem;
        text-decoration: none;
        transition-duration: .3s;

        @include mobile {
            right: auto;
            left: 50%;
            bottom: 2rem;
            transform: translateX(-50%);
        }

        &:hover {
            transform: scale(1.1);
        }
    }
}
